// @Definition: The main entry point of the React application, responsible for rendering the app with authentication, messaging, and language providers.
import React, { lazy, Suspense, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CssBaseline, ThemeProvider, Box, CircularProgress } from '@mui/material';
import { AuthProvider } from './contexts/AuthContext';
import { CalendarProvider } from './contexts/CalendarContext';
import { MessageProvider } from './contexts/MessageContext';
import { HelmetProvider } from 'react-helmet-async';
import LanguageProvider from './components/LanguageProvider';
import NavigationBar from './components/NavigationBar';
import CookieBanner from './components/CookieBanner';
import PrivateRoute from './components/PrivateRoute';
import MessageSnackbar from './components/MessageSnackbar';
import ResourcePreloader from './components/ResourcePreloader';
import BetaBanner from './components/BetaBanner';
import theme from './theme';
import * as Sentry from "@sentry/react";

// Lazy load page components
const HomePage = lazy(() => import('./pages/HomePage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const BetaRegisterPage = lazy(() => import('./pages/BetaRegisterPage'));
const BetaRequestPage = lazy(() => import('./pages/BetaRequestPage'));
const ProfilePage = lazy(() => import('./pages/ProfilePage'));
const TermsAndPrivacyPage = lazy(() => import('./pages/TermsAndPrivacyPage'));
const PricingPage = lazy(() => import('./pages/PricingPage'));
const AboutUsPage = lazy(() => import('./pages/AboutUsPage'));
const CalendarManagementPage = lazy(() => import('./pages/CalendarManagementPage'));
const CalendarView = lazy(() => import('./pages/CalendarViewPage'));

// Loading component
const LoadingFallback = () => (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
    </Box>
);

// Sentry configuration
Sentry.init({
    dsn: "https://d36779875c38e07407905c094e1e493d@o4508032835584000.ingest.de.sentry.io/4508049005281360",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", "https://yearataglance.com", /^https:\/\/yearataglance\.app\/api\//],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

const App = () => {
    const [isBannerVisible, setIsBannerVisible] = useState(true);

    const handleBannerDismiss = () => {
        setIsBannerVisible(false);
    };

    return (
        <HelmetProvider>
            <AuthProvider>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <LanguageProvider>
                        <MessageProvider>
                            <Router>
                                <CalendarProvider>
                                <ResourcePreloader />
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    minHeight: '100vh',
                                    pt: isBannerVisible ? '24px' : 0,
                                    transition: 'padding-top 0.3s ease-in-out'
                                }}>
                                    <BetaBanner onDismiss={handleBannerDismiss} />
                                    <Suspense fallback={<LoadingFallback />}>
                                        <Routes>
                                            <Route path="/" element={<HomePage />} />
                                            <Route path="/login" element={<LoginPage />} />
                                            <Route path="/beta-register" element={<BetaRegisterPage />} />
                                            <Route path="/beta-request" element={<BetaRequestPage />} />
                                            <Route path="/terms-and-privacy" element={<TermsAndPrivacyPage />} />
                                            <Route path="/pricing" element={<PricingPage />} />
                                            <Route path="/about" element={<AboutUsPage />} />
                                            <Route
                                                path="/*"
                                                element={
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>

                                                        <NavigationBar isBannerVisible={isBannerVisible} />
                                                        <Box component="main" sx={{ flexGrow: 1, pt: 8 }}>
                                                            <Routes>
                                                                <Route path="/calendars" element={<PrivateRoute><CalendarManagementPage /></PrivateRoute>} />
                                                                <Route path="/calendar" element={<PrivateRoute><CalendarView /></PrivateRoute>} />
                                                                <Route path="/profile" element={<PrivateRoute><ProfilePage /></PrivateRoute>} />
                                                            </Routes>
                                                        </Box>
                                                    </Box>
                                                }
                                            />
                                        </Routes>
                                    </Suspense>
                                    <CookieBanner />
                                    <MessageSnackbar />
                                </Box>
                                </CalendarProvider>
                            </Router>
                        </MessageProvider>
                    </LanguageProvider>
                </ThemeProvider>
            </AuthProvider>
        </HelmetProvider>
    );
};

export default App;