// @Definition: AuthContext.js defines an AuthContext and AuthProvider component to manage user authentication in the yearataglance project.
import React, { createContext, useState, useContext, useEffect } from 'react';
import { apiV1 } from '../services/api';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadUserFromStorage = async () => {
      const storedUser = localStorage.getItem('user');
      const token = localStorage.getItem('token');

      if (storedUser && token) {
        setUser(JSON.parse(storedUser));
        apiV1.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        try {
          // Verifica che il token sia ancora valido
          await apiV1.get('/users/profile');
        } catch (error) {
          // Se il token non è più valido, effettua il logout
          console.error('Token validation error:', error);
          logout();
        }
      }
      setLoading(false);
    };

    loadUserFromStorage();
  }, []);

  const login = async (email, password) => {
    try {
      const response = await apiV1.post('/auth/login', { email, password });
      // console.log(response.data);
      const { user, token } = response.data;
      if(token) {
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('token', token);
        apiV1.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        setUser(user);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log('Login error:', error);
      return false;
    }
  };

  const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    delete apiV1.defaults.headers.common['Authorization'];
    setUser(null);
  };

  const register = async (username, email, password) => {
    try {
      const response = await apiV1.post('/auth/register', { username, email, password });
      const { user, token } = response.data;
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', token);
      apiV1.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setUser(user);
      return true;
    } catch (error) {
      console.error('Registration error:', error);
      return false;
    }
  };

  const registerBeta = async (username, email, password, inviteCode) => {
    try {
      const response = await apiV1.registerBeta({ username, email, password, inviteCode });
      const { user, token } = response.data;
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', token);
      apiV1.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setUser(user);
      return true;
    } catch (error) {
      console.error('Beta registration error:', error);
      return false;
    }
  };

  const requestBetaAccess = async (email) => {
    try {
      await apiV1.requestBetaAccess(email);
      return true;
    } catch (error) {
      console.error('Beta access request error:', error);
      return false;
    }
  };

  const updateUser = (updatedUser) => {
    localStorage.setItem('user', JSON.stringify(updatedUser));
    setUser(updatedUser);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, register,registerBeta, requestBetaAccess, updateUser, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);